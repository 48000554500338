import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SendIcon from '@mui/icons-material/Send';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import axios from 'axios';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { DataGrid } from '@mui/x-data-grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { apiUrl } from '../../apiUrl/ApiUrl';


const classChoose = [
    {
      value: '客供入庫',
      label: '客供入庫',
    },
    {
      value: '客供取消',
      label: '客供取消',
    },
    {
      value: '材料需求',
      label: '材料需求',
    },
    {
      value: '盤盈',
      label: '盤盈',
    },
    {
      value: '盤虧',
      label: '盤虧',
    },
    {
      value: '報廢',
      label: '報廢',
    },
    {
      value: '',
      label: '不選擇',
    },
  ];


export default function StockChange({data}) {
  const columns = [
    { field: 'id', headerName: 'ID', width:60 },
    {
        field: 'classModel',
        headerName: '類別',
        width: 90,
        editable: true,
    },
    {
        field: 'piNum',
        headerName: '批號',
        width: 150,
        editable: true,
    },
    {
        field: 'date',
        headerName: '日期',
        width: 250,
        editable: true,
    },
    {
        field: 'drawNum',
        headerName: '圖號',
        sortable: false,
        width: 160,
        editable: true,
    },
    {
        field: 'qty',
        headerName: '數量',
        sortable: false,
        width: 80,
        editable: true,
    },
    {
        field: 'applicant',
        headerName: '申請人',
        sortable: false,
        width: 100,
        editable: true,
    },
    {
      field: 'signOff',
      headerName: '簽核',
      width: 250,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        if (params.value) {
          // If there's a value, just display it
          return <span>{params.value}</span>;
        } 
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => handleApprove(params.id)}
              >
                通過
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => handleReject(params.id)}
              >
                拒絕
              </Button>
            </>
          );
        
      },
    },
    {
        field: 'signOffdDate',
        headerName: '簽核日期',
        sortable: false,
        width: 160,
        editable: true,
    },
    ];
    const [rows, setRows] = React.useState([]);
    const [classValue, setClassValue] = useState('客供入庫');
    const [date, setDate] = useState('');
    const [piNum, setPiNum] = useState('');
    const [drawNo, setDrawNo] = useState('');
    const [qty, setQty] = useState(null);
    const [isCreate,setIsCreate] = useState(false);
    useEffect(() => {
        if (data && data.stoChanges) {
          setRows(data.stoChanges);
        }
      }, [data]); 


    const handleSearch = async () => {
    try {
        const response = await axios.get(`${apiUrl}/Storage/GetChanges?classModel=${classValue}&date=${date}&piNum=${piNum}&drawNum=${drawNo}`);
        if(response.data.stoChanges != null) {
        setRows(response.data.stoChanges);
        alert('搜尋成功');
        }else {
        alert('找無資料');
        }

    } catch (error) {
        console.error('Failed to fetch user data:', error);
        alert('找無資料');
    }
    };  

    const handleApprove =async (id) => {
      try {
        const user = sessionStorage.getItem('userName')
        const response = await axios.get(`${apiUrl}/Storage/Approve?id=${id}&name=${user}`);
        if(response.status === 200) {
          handleSearchNotYet();
        }else {
          alert('失敗');
        }

      } catch (error) {
          console.error('Failed to fetch user data:', error);
          alert('失敗');
      }
    };
    
    const handleReject = async(id) => {
      try {

        const response = await axios.get(`${apiUrl}/Storage/Reject?id=${id}`);
        if(response.status === 200) {
          handleSearchNotYet();
        }else {
          alert('失敗');
        }

      } catch (error) {
          console.error('Failed to fetch user data:', error);
          alert('失敗');
      }
    };


    const handleSearchNotYet = async () => {
        try {
            const response = await axios.get(`${apiUrl}/Storage/GetNotYetChanges`);
            if(response.data.stoChanges != null) {
            setRows(response.data.stoChanges);
            alert('搜尋成功');
            }else {
            alert('搜尋失敗');
            }
    
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            alert('搜尋失敗');
        }
    };  
    const handleModel = () => {
      setIsCreate(!isCreate);
      if(!isCreate) {
        // 建立一个新的Date对象，它代表当下的日期和时间
        const now = new Date();
        
        // 格式化日期为YYYY-MM-DD
        const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
    
        // 设置日期
        setDate(formattedDate);
      } else {
        setDate('');
      }
    };
    
    
  
    const handleAddSave = async () => {
        try {
          const user = sessionStorage.getItem('userName'); // 获取存储在SESSION中的USER
          const classModel = classValue;
          if(isCreate && classValue === '') {
            alert('類別要選擇');
            return;
          }
          const response = await axios.post(`${apiUrl}/Storage/CreateChange`, {
            classModel,
            date,
            piNum,
            drawNo,
            qty,
          }, {
            headers: {
              'User': user,
            },
          });
      
          if (response.data.statusCode) {
            alert('添加成功');
            // 可以在这里添加其他成功的处理逻辑
          } else {
            alert('添加失败');
          }
        } catch (error) {
          console.error('Failed to add data:', error);
          alert('添加失败');
        }
      };
    
  return (
    <>
        <Box
        component="form"
        sx={{
            '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete="off"
        >
        <TextField
            id="outlined-select-currency-native"
            select
            label="類別Class"
            defaultValue="客供入庫"
            SelectProps={{
            native: true,
            }}
            helperText="Please select your Class"
            onChange={(e)=>setClassValue(e.target.value)}
            value={classValue}
        >
            {classChoose.map((option) => (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
            ))}
        </TextField>
        <FormControl>
            <InputLabel htmlFor="component-outlined">日期Date</InputLabel>
            <OutlinedInput
            onChange={(e)=>setDate(e.target.value)}
            value={date}
            id="component-outlined"
            variant="outlined"
            placeholder='yyyy-mm-dd'
            label="Name"
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="component-outlined">批號</InputLabel>
            <OutlinedInput
            onChange={(e)=>setPiNum(e.target.value)}
            value={piNum}
            id="component-outlined"
            defaultValue=""
            label="Name"
            readOnly={!isCreate}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="component-outlined">圖號Draw no.</InputLabel>
            <OutlinedInput
            onChange={(e)=>setDrawNo(e.target.value)}
            value={drawNo}
            id="component-outlined"
            defaultValue=""
            label="Name"
            readOnly={!isCreate}
            />
        </FormControl>
        <FormControl>
            <InputLabel htmlFor="component-outlined">數量Qty</InputLabel>
            <OutlinedInput
            onChange={(e)=>setQty(e.target.value)}
            value={qty}
            id="component-outlined"
            defaultValue=""
            label="Name"
            placeholder='請符合資料庫格式'
            readOnly={!isCreate}
            />
        </FormControl>     
        </Box>
            <Box sx={{ height: 600, width: '100%' }}>
            <Tooltip title="Add" arrow>
                <Button endIcon={<SearchIcon />} onClick={handleSearch}>搜尋</Button>
                <Button endIcon={<AddIcon />} onClick={handleModel}>{isCreate?'新增':'搜尋'}</Button>
                <Button endIcon={<SaveIcon />} onClick={handleAddSave}>保存</Button>
                <Button endIcon={<ReceiptLongIcon/>} onClick={handleSearchNotYet}>庫存異動</Button>
            </Tooltip>
            <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                paginationModel: {
                    pageSize: 100,
                },
                },
            }}
            pageSizeOptions={[100,75,50]}
            disableRowSelectionOnClick
            />
        </Box>   
      </>
  );
}