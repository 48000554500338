import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Document, Page, Text, StyleSheet, View, Image, PDFDownloadLink, PDFViewer, Font, Svg } from '@react-pdf/renderer';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { apiUrl } from '../../apiUrl/ApiUrl';

dayjs.extend(utc);
dayjs.extend(timezone);

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ToShip({ data }) {
    const [newData, setNewData] = useState(null);
    const [model, setModel] = useState('出貨交運單');
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [createRows, setCreateRows] = useState([]);
    const [orderRows, setOrderRows] = useState([]);
    const [coms, setComs] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [way, setWay] = useState('drawNum');
    const [num, setNum] = useState('');
    const [barCode, setBarCode] = useState('');
    const [isCreate, setIsCreate] = useState(false);
   
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [month, setMonth] = useState("");
    const [detailData, setDetailData] = useState({
        toShipNum: '', // 初始化為空字符串或其他適當的初始值
        toShipKind: '', // 假設還有其他屬性
        // 添加其他需要的屬性
    });

    // 定義 handleRefresh 函數
    const handleRefresh = () => {
        setDetailData(prevDetailData => ({
            ...prevDetailData,
            toShipNum: '' // 重置為空字符串或其他適當的初始值
        }));
        setCreateRows([]);
        setRows([]);

    };

    useEffect(() => {
        console.log("Month state updated:", month);
    }, [month]);

    const columns = [
        
        { field: '批號', headerName: '批號', width: 110, editable: true },
        { field: '圖號', headerName: '圖號', width: 110, editable: true },
        { field: '機種', headerName: '機種', width: 60, editable: true },
        { field: '物品名', headerName: '物品名', width: 110, editable: true },
        { field: '訂單數量', headerName: '訂單數量', width: 90, editable: true },
        { field: '實交貨數', headerName: '實交貨數', width: 90, editable: true },
        { field: '單位', headerName: '單位', width: 60, editable: true },
        { field: '訂單號碼', headerName: '訂單號碼', width: 120, editable: true },
        { field: '流水號', headerName: '流水號', width: 60, editable: true },
        { field: '出貨備註', headerName: '出貨備註', width: 110, editable: true },
        { field: 'id', headerName: 'ID', width: 90 },
    ];

    const orderColumns = [
        { field: '批號', headerName: '批號', width: 110, editable: true },
        { field: '圖號', headerName: '圖號', width: 110, editable: true },
        { field: '項次', headerName: '項次', width: 60, editable: true },
        { field: '機種', headerName: '機種', width: 110, editable: true },
        { field: '訂單數量', headerName: '訂單數量', width: 90, editable: true },
        {
            field: '未交貨數',
            headerName: '未交貨數',
            width: 90,
            editable: false, // 這個列不需要手動編輯
            valueGetter: (params) => {
                // 計算未交貨數
                const 訂單數量 = params.row.訂單數量 || 0;
                const 實交貨數 = params.row.實交貨數 || 0;
                const 累計交貨數量=params.row.累計交貨數量 || 0;
                return 訂單數量 - 實交貨數 - 累計交貨數量;
            }
        },
        { field: '實交貨數', headerName: '實交貨數', width: 110, editable: true },
        { field: '訂單號碼', headerName: '訂單號碼', width: 110, editable: true },
        { field: '流水號', headerName: '流水號', width: 70, editable: true },
        { field: '物品名', headerName: '物品名', width: 110, editable: true },
        { field: '單位', headerName: '單位', width: 110, editable: true },
        { field: '承製公司', headerName: '承製公司', width: 110, editable: true },
        {
            field: 'remove',
            headerName: '移除',
            width: 110,
            renderCell: (params) => {
                const onClick = () => {
                    removeRow(params.row.id);
                };
                return isCreate ? <button type='button' onClick={onClick}>移除</button> : null;
            }
        },
        { field: 'memo', headerName: '出貨備註', width: 110, editable: true },
        { field: 'id', headerName: 'ID', width: 90 },
    ];


    const [splitDialogOpen, setSplitDialogOpen] = useState(false); // 控制對話框顯示
    const [splitQuantity, setSplitQuantity] = useState(0); // 儲存分拆數量
    const [selectedRow, setSelectedRow] = useState(null); // 儲存選擇的行資料

    // 打開分拆對話框
    const handleAccountSharing = (rows) => {
        setSelectedRow(rows); // 設置選中的行
        setSplitDialogOpen(true); // 打開對話框
    };

    // 提交分拆
    const handleSplitSubmit = async () => {
        if (!splitQuantity || splitQuantity <= 0) {
            alert('請輸入有效的分拆數量');
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/ToShip/Split?toShipNum=${detailData.toShipNum}&id=${selectedRow?.id}&quantity=${splitQuantity}`);

            if (response.status === 200) {
                alert('分拆成功！');
                setSplitDialogOpen(false); // 關閉對話框
                setRows(response.data.orderDtos);
            } else {
                alert('分拆失敗，請稍後再試');
            }
        } catch (error) {
            console.error('分拆失敗:', error);
            alert('分拆失敗，請稍後再試');
        }
    };

    // 關閉對話框
    const handleDialogClose = () => {
        setSplitDialogOpen(false);
        setSplitQuantity(0);
        setSelectedRow(null);
    };

    const workMargin = {
        marginTop: '3%',
        marginBottom: '5%'
    };

    const removeRow = (id) => {
        setCreateRows(prevCreateRows => prevCreateRows.filter(row => row.id !== id));
    };
    

    const setModelandGetDate = (newModel) => {
        setModel(newModel);
    };

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const fetchOrders = async () => {
        try {
            // 使用空字符串作为默认值，如果 selectedOption 是 null 或 undefined
            const client = selectedOption ? selectedOption.manufacturer_AbbreviationName : '';
            const response = await axios.get(`${apiUrl}/ToShip/Orderlist?client=${client}&num=${num}&way=${way}&barcode=${barCode}`);
            // 检查响应的结果，并设置到 state
            if (response.status === 200) {
                setOrderRows(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // 可选：在 UI 中显示错误消息
            // setError('Failed to fetch data. Please try again.');
        }
    };

    const fetchOptions = async () => {
        try {
            const response = await axios.get(`${apiUrl}/Manufacturer/list`);
            if (response.status === 200) {
                setOptions(response.data.manufacturers);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAddToShip = (params) => {
        setIsCreate(true);
        // 檢查 createRows 中是否已存在該行
        if (!createRows.some(row => row.id === params.row.id)) {
            // 將新行添加到 createRows
            setCreateRows(prevCreateRows => [...prevCreateRows, { ...params.row }]);
        }
    };
    

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
    };

    const handleSave = async () => {
        const modifiedRows = createRows.map(item => ({
            ...item,
        }));
        console.log(modifiedRows);
        console.log(processRowUpdate);

        const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');

        // 檢查實交貨數是否大於訂單數量
        const hasInvalidShipNum = modifiedRows.some(item => item.實交貨數 > item.訂單數量);
        if (hasInvalidShipNum) {
            alert('實交貨數不能大于訂單數量');
            return; // 停止執行更多的代碼
        }

        // 如果沒有問題，繼續執行
        const toShipData = {
            ToShipDate: formattedDate,
            Client: selectedOption ? selectedOption.manufacturer_AbbreviationName : '',
            SellWay: detailData.toShipKind,
            Orders: modifiedRows,
            ShipNum: detailData.toShipNum || ''
        };

        try {
            const response = await axios.post(`${apiUrl}/ToShip/OrderToShip`, toShipData);
            if (response.status === 200) {
                alert(`出貨成功\n出貨單號: ${response.data.shipNum}`);

                setDetailData(prevDetailData => {
                    const updatedData = {
                        ...prevDetailData,
                        toShipNum: response.data.shipNum // 確保屬性名稱一致
                    };
                    console.log('Updated toShipNum:', updatedData.toShipNum); // 正確訪問更新後的值
                    console.log('Updated detailData:', updatedData);
                    return updatedData;
                });
            }
            console.log("單號", detailData.toShipNum); // 這裡可能仍然是舊的值
        } catch (error) {
            alert(`出貨失敗: ${error.response?.data?.message || error.message}`);
            console.error('出貨失敗:', error);
            // 這裡處理錯誤情況，可能需要更新UI來顯示錯誤信息
        }
    };

    // 使用 useEffect 監聽 detailData.toShipNum 的變化
    useEffect(() => {
        if (detailData.toShipNum) {
            console.log('useEffect - Updated toShipNum:', detailData.toShipNum);
            console.log('useEffect - Updated detailData:', detailData);
            // 這裡可以添加需要在狀態更新後執行的其他邏輯
        }
    }, [detailData.toShipNum]);

    const handleRowSelection = (params) => {
        console.log("Selected row data:", params.row); // 調試信息
        setSelectedRow(params.row); // 更新選中的行數據
    };

    const handleOldSave = async () => {
        const modifiedRows = rows.map(item => ({
            ...item,
        }));
        const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
        console.log(selectedDate);
        // 检查實交貨數是否大于訂單數量
        // eslint-disable-next-line no-restricted-syntax
        for (const item of modifiedRows) {
            if (item.實交貨數 > item.訂單數量) {
                alert('實交貨數不能大于訂單數量');
                return; // 停止执行更多的代码
            }
        }

        // 如果没有问题，继续执行
        const toShipData = {
            ToShipDate: formattedDate,
            Client: selectedOption.manufacturer_AbbreviationName,
            SellWay: detailData.toShipKind,
            Orders: modifiedRows,
            ShipNum: detailData.toShipNum || ''
        };

        try {
            const response = await axios.post(`${apiUrl}/ToShip/OrderToShipOld`, toShipData);
            if (response.status === 200) {
                alert(`修改舊資料成功\n出貨單號: ${response.data.shipNum}`);
                setDetailData(prevDetailData => ({
                    ...prevDetailData,
                    toShipNum: response.data.shipNum,
                }));
                // 这里可以添加更多的处理逻辑，比如更新UI或状态
                console.log(detailData.toShipNum);
            }
            console.log("單號", detailData.toShipNum);
        } catch (error) {
            alert(`出貨失敗: ${error.response?.data?.message || error.message}`);
            console.error('出貨失敗:', error);
            // 这里处理错误情况，可能需要更新UI来显示错误信息
        }
    };

    const handlePrint = (toShipNum) => {
        if (!toShipNum) {
            alert('出貨單號不存在，無法列印。');
            return;
        }

        axios.get(`${apiUrl}/ToShip/CreateExcel?num=${toShipNum}`, {
            responseType: 'blob',  // 指定响应类型为 Blob
            headers: {
                'Id': localStorage.getItem('Id')
            }
        })
            .then(response => {
                if (response.data) {
                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

                    // Open the PDF in a new window
                    window.open(url, '_blank');

                    // Clean up the URL object
                    window.URL.revokeObjectURL(url);

                    alert('PDF 文件已開啟');
                } else {
                    alert('列印失败');
                }
            })
            .catch(error => {
                console.error('發生錯誤:', error);
                alert('發生錯誤');
            });
    };

    const handleExecl = (toShipNum) => {
        if (!toShipNum) {
            alert('出貨單號不存在，無法輸出。');
            return;
        }

        axios.get(`${apiUrl}/ToShip/CovertToExcel?num=${toShipNum}`, {
            responseType: 'blob',  // 指定响应类型为 Blob
            headers: {
                'Id': localStorage.getItem('Id')
            }
        })
        .then((response) => {
            // 創建 Blob URL
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
    
            // 設定下載檔案的名稱
            link.setAttribute('download', '出貨交運單.xlsx');
    
            // 觸發下載
            document.body.appendChild(link);
            link.click();
    
            // 清除 DOM 中的元素
            link.remove();
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('下載失敗:', error);
            alert('發生錯誤，無法下載檔案');
        });
    };
    const processRowUpdate = (newRow, oldRow) => {
        if (isCreate) {
            // 更新 createRows，替換現有行
            setCreateRows(prevCreateRows => 
                prevCreateRows.map(row => row.id === newRow.id ? { ...newRow } : row)
            );
    
            // 如果 '訂單數量' 或 '實交貨數' 發生變化，更新 '未交貨數'
            if (newRow.訂單數量 !== oldRow.訂單數量 || newRow.實交貨數 !== oldRow.實交貨數) {
                return {
                    ...newRow,
                    未交貨數: newRow.訂單數量 - newRow.實交貨數,
                    出貨備註: newRow.出貨備註,
                };
            }
    
            return newRow;
        }
    
        // 如果不是 createRows，則更新 rows
        setRows(prevRows => 
            prevRows.map(row => row.id === newRow.id ? { ...newRow } : row)
        );
    
        // 如果 '訂單數量' 或 '實交貨數' 發生變化，更新 '未交貨數'
        if (newRow.訂單數量 !== oldRow.訂單數量 || newRow.實交貨數 !== oldRow.實交貨數) {
            return {
                ...newRow,
                未交貨數: newRow.訂單數量 - newRow.實交貨數,
                出貨備註: newRow.出貨備註,
            };
        }
    
        return newRow;
    };
    
    const handleSupplementSubmit = async () => {
        try {
            const response = await axios.get(`${apiUrl}/ToShip/DefectiveProducts?drawNum=${num}`);
            if (response.status === 200) {
                const validData = response.data.filter(item => item.id); // 過濾掉沒有 ID 的資料
                setOrderRows(validData);
            } else {
                alert('補交資料搜尋失敗');
            }
        } catch (error) {
            alert('補交資料搜尋失敗，請稍後再試。');
            console.error(error);
        }
    };
    
    

    const handleSellChange = (event) => {
        // 更新 detailData 状态，特别是 toShipKind 属性
        setDetailData({
            ...detailData,
            toShipKind: event.target.value,
        });
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    return (
        <div className='container-fluid'>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    <SelectFilter setMonth={setMonth} setNewData={setNewData} />
                </div>
            </div>
            <div className='row'>
                <div className='col-3'>
                    <h2><span className="badge bg-dark">出貨資料</span></h2>
                </div>
                <div className='col-1' />
                <div className='col-4 d-flex align-items-center'>
                    <h2><span className="badge bg-dark">訂單資料</span></h2>
                </div>
                <div className='col-4 d-flex align-items-center justify-content-end'>
                    <Button variant="contained" onClick={handleRefresh}>X新增</Button>
                </div>
            </div>
            <div className='row'>
                <div className='col-3'>
                    {data != null && model === '出貨交運單' ? (
                        <ListTable
                            setDetailData={setDetailData}
                            setNewData={setNewData}
                            data={newData || data}
                            setModel={setModelandGetDate}
                            page={page}
                            setPage={setPage}
                            setRows={setRows}
                            setComs={setComs}
                            setSelectedDate={setSelectedDate}
                            options={options}
                            setSelectedOption={setSelectedOption}
                            setIsCreate={setIsCreate}
                            month={month}  // 確保傳遞 month 作為 props
                        />
                    ) : (
                        <div>目前還沒取得資料或取不到資料</div>
                    )}
                </div>
                <div className='col-1' />
                <div className='col-7'>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={options}
                                            sx={{ width: '100%' }}
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                            getOptionLabel={(option) => option.manufacturer_Seq + option.manufacturer_AbbreviationName}
                                            renderInput={(params) => <TextField {...params} label="廠商" />}
                                        />
                                        {/* <InputLabel id="demo-simple-select-label">客戶</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={detailData != null ? detailData.client : ''}
                                            onChange={handleClientChange}
                                        >
                                            {coms.map((item) => (
                                                <MenuItem key={item.id} value={item.簡稱}>
                                                    {item.簡稱}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">出貨庫別</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={detailData != null ? detailData.toShipKind : ''}
                                            // label="Age"
                                            onChange={handleSellChange}
                                        >
                                            <MenuItem key={1} value='銷貨出庫'>
                                                銷貨出庫
                                            </MenuItem>
                                            <MenuItem key={2} value='托外出庫'>
                                                托外出庫
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-basic"
                                        label="出貨單號"
                                        variant="outlined"
                                        value={detailData != null ? detailData.toShipNum : ''}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="outlined-basic" label="聯絡人" variant="outlined"
                                        value={selectedOption ? selectedOption.manufacturer_ContactName : ''}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" label="電話" variant="outlined" style={{ width: '100%' }}
                                        value={selectedOption ? selectedOption.manufacturer_CompanyPhone : ''} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" label="送貨地址" variant="outlined" style={{ width: '100%' }}
                                        value={selectedOption ? selectedOption.manufacturer_ShipAddress : ''} />
                                </Grid>
                                <Grid item xs={5}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="出貨日期" value={selectedDate} onChange={handleDateChange} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box sx={{ display: 'flex', gap: '8px' }}> {/* gap 設置按鈕間距 */}
                                        <Button variant="contained" onClick={handleSave}>儲存</Button>
                                        <Button variant="contained" onClick={handleOldSave}>修改</Button>
                                        <Button variant="contained" onClick={() => handlePrint(detailData.toShipNum)}>列印</Button>
                                        <Button variant="contained" onClick={() => handleExecl(detailData.toShipNum)}>Excel</Button>
                                        <Button variant="contained" onClick={() => handleAccountSharing(selectedRow)} >分拆</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ height: 450, width: '100%' }}>
                            <DataGrid
                                rows={isCreate ? createRows : rows}
                                columns={!isCreate ? columns : orderColumns}
                                onRowClick={(params) => setSelectedRow(params.row)} // 設置選中的行數據
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 75,
                                        },
                                    },
                                }}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={error => alert(error)}
                                // 修正 onRowEditCommit
                                pageSizeOptions={[100]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                        <Box sx={{ height: 600, width: '100%' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">未出貨訂單</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={way}
                                                onChange={(e) => setWay(e.target.value)}
                                            >
                                                <FormControlLabel value="orderNum" control={<Radio />} label="訂單號碼" />
                                                <FormControlLabel value="drawNum" control={<Radio />} label="圖號" />
                                                <FormControlLabel value="piNum" control={<Radio />} label="批號" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <TextField id="outlined-basic" label="圖號" variant="outlined"
                                            value={num} onChange={(e) => setNum(e.target.value)} />
                                        <Button variant="contained" color="secondary" onClick={handleSupplementSubmit}>補交</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField id="outlined-basic" label="條碼" variant="outlined"
                                            value={barCode} onChange={(e) => setBarCode(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" onClick={fetchOrders}>搜尋</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ height: 450, width: '100%' }}>
                                <DataGrid
                                    rows={orderRows}
                                    columns={orderColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 100,
                                            },
                                        },
                                    }}
                                    onCellClick={handleAddToShip}
                                    pageSizeOptions={[100]}
                                    disableRowSelectionOnClick
                                />
                            </Box>
                        </Box>
                    </Box>
                </div>
                <div className='col-1' />
                </div>

                {/* 分拆對話框 */}
                <Dialog open={splitDialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>分拆訂單</DialogTitle>
                        <DialogContent>
                            <TextField
                            label="分拆數量"
                            type="number"
                            value={splitQuantity}
                            onChange={(e) => setSplitQuantity(Number(e.target.value))}
                            fullWidth
                            margin="dense"
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDialogClose}>取消</Button>
                <Button onClick={handleSplitSubmit} variant="contained">提交</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function ListTable({ data, setNewData, setRows, setDetailData, page, setPage, setComs, setSelectedDate, setIsCreate, options, setSelectedOption, month }) {
    const thStyle = {
        centered: {
            textAlign: 'center',
        }
    };
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    };

    const fetchCom = async (client) => {
        try {
            const response = await axios.get(`${apiUrl}/ToShip/Comlist?com=${client}`);
            // 檢查響應的結果，並設置到 state
            if (response.status === 200) {
                setComs(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const nextPage = () => {
        console.log("月份", month); // 确认 month 的值
        axios.get(`${apiUrl}/ToShip/GetMains?month=${month}&page=${page + 1}`)
            .then(response => {
                if (response.data.statusCode === 200) {
                    setNewData(response.data);
                    setPage(page + 1);
                } else {
                    alert('已經是最後一筆了');
                }
            })
            .catch(error => {
                // handle error
                console.log(error);
            });
    };

    const prePage = () => {
        console.log("月份", month);
        axios.get(`${apiUrl}/ToShip/GetMains?month=${month}&page=${page - 1}`)
            .then(response => {
                if (response.data.statusCode === 200) {
                    setNewData(response.data);
                    setPage(page - 1);
                }
            })
            .catch(error => {
                // handle error
                console.log(error);
            });
    };

    const GetDetails = (toShipNum, client) => {
        axios.get(`${apiUrl}/ToShip/GetMainAndDetails?toShipNum=${toShipNum}`)
            .then(response => {
                if (response.data.statusCode === 200) {
                    setIsCreate(false);
                    setDetailData(response.data.toShip);
                    setRows(response.data.orderDtos);
                    console.log(response.data.toShip);
                    setSelectedDate(dayjs(response.data.toShip.toShipDate));
                    const matchedOption = options.find(option => option.manufacturer_AbbreviationName === response.data.toShip.client);
                    setSelectedOption(matchedOption);
                    fetchCom(client);
                } else {
                    alert('找不到資料');
                }
            })
            .catch(error => {
                // handle error
                console.log(error);
            });
    };

    return (
        <>
            <div style={{ overflow: 'auto', maxHeight: '500px', width: '100%' }}> {/* 縮小表格顯示範圍 */}
                <table className="table" style={{ width: '100%' }}> {/* 限制表格寬度 */}
                    <thead>
                        <tr>
                            <th scope="col" style={{ textAlign: 'center', width: '10%' }}>#</th>
                            <th scope="col" style={{ textAlign: 'center', width: '30%' }}>出貨單號</th>
                            <th scope="col" style={{ textAlign: 'center', width: '30%' }}>客戶</th>
                            <th scope="col" style={{ textAlign: 'center', width: '30%' }}>操作</th>
                        </tr>
                    </thead>
                <tbody>
                    {data.toShips.map((item, index) => (
                        <tr key={item.id}>
                            <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                            <td style={{ textAlign: 'center' }}>{item.toShipNum}</td>
                            <td style={{ textAlign: 'center' }}>{item.client}</td>
                            <td style={{ textAlign: 'center' }}>
                            <Button variant="contained" color="warning" onClick={() => GetDetails(item.toShipNum, item.client)}>詳細</Button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div style={{ textAlign: 'center' }} className='mt-3'>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                <span style={{ margin: '0 10px' }}>目前在第{page}頁</span>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
            </div>
        </>
    );
}

function SelectFilter({ setNewData, setMonth }) {
    const [localMonth, setLocalMonth] = useState("");
    const [client, setClient] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint

        console.log("Local Month:", localMonth); // 確認 localMonth 的值
        setMonth(localMonth);

        const page = 1;
        const Realapiurl = `${apiUrl}/ToShip/GetMains`;
        const params = {
            month: localMonth,
            client,
            page
        };

        try {
            const response = await axios.get(Realapiurl, { params });
            setNewData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            // 可選：在 UI 中顯示錯誤消息
            // setError('Failed to fetch data. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-6'>
                        <label htmlFor="inputText1">日期
                            <input type="text" placeholder='yyyy/mm/dd' className="form-control" id="inputText1" value={localMonth} onChange={(e) => setLocalMonth(e.target.value)} />
                        </label>
                    </div>
                    <div className='col-6'>
                        <label htmlFor="inputText2">客戶
                            <input type="text" className="form-control" id="inputText2" value={client} onChange={(e) => setClient(e.target.value)} />
                        </label>
                    </div>
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                    <div className='col-12'>
                        <button type="submit" className="btn btn-dark">提交</button>
                    </div>
                </div>
            </div>
        </form>
    );
}
