import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function PurchasePrice({data}) {
    const [newData,setNewData] = useState(null);
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('主表搜尋');
    const [editItem,setEditItem] = useState('');
    const [editDetail,setEditDetail] = useState(null);
    const [page,setPage] = useState(1);
    const [drawNum,setDrawNum] = useState('');
    const [itemName,setItemName]  = useState('');
console.log(data)
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 
    console.log(data)


    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    <ChooseModel setModel={setModel} setNewData={setNewData}/>
                </div>
                <div className='col-5'>
                    {model === '主表新增' ? <InsertMainForm setNewData={setNewData}/> : null} 
                    {model === '副表新增' ? <InsertDetailForm setDetailData={setDetailData}/> : null} 
                    {model === '主表搜尋' ? <SelectMainForm setNewData={setNewData} itemName={itemName} setItemName={setItemName}
                    setDetailData={setDetailData} page={page} setPage={setPage} setDrawNum={setDrawNum} drawNum={drawNum}/> : null}  
                    {model === '主表修改'? <EditMainForm setNewData={setNewData} editItem={editItem}/> : null }
                    {model === '副表修改'? <EditDetailForm setNewData={setNewData} editDetail={editDetail} setDetailData={setDetailData}/> : null }
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">進價紀錄</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {detailData != null ? <DetailListTable data={detailData} setDetailData={setDetailData}
                    setEditDetail={setEditDetail}
                    setModel={setModel}/> : <div>目前沒有副表資料</div>}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">廠商報價</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null?<ListTable setDetailData={setDetailData} setNewData={setNewData} data={newData || data} 
                    newDrawNum = {drawNum}
                    setModel={setModel} page={page} setPage={setPage}
                    setEditItem={setEditItem}/> :null}
                </div>              
            </div>
        </div>     
    )
}
function ListTable({data,setNewData,setModel,setDetailData,page,setPage,setEditItem,newDrawNum}) {
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnMargin = {
        marginLeft: '2%'
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }
    const [drawNum, setDrawNum] = React.useState('');

    const nextPage = () => {

        axios.get(`${apiUrl}/PurchasePrice/MainList?page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }

    const prePage = () => {

        axios.get(`${apiUrl}/PurchasePrice/MainList?page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }

    const handlePrint = () => {
        axios.get(`${apiUrl}/PurchasePrice/CreateExcel?drawNum=${newDrawNum||drawNum}`, {
            responseType: 'blob' // Tell axios to expect a Blob
        })
        .then(response => {
            if (response.data) {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
                const day = String(today.getDate()).padStart(2, '0');
                const filename = `進價調價紀錄${year}${month}${day}.xlsx`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', filename); // or any other filename you want
                document.body.appendChild(link);
                link.click();
                alert('列印成功');
            } else {
                alert('列印失敗 圖號不得為空');
            }
        })
        .catch(error => {
            console.log(error);
            alert('發生錯誤 圖號不得為空');
        });
    };
    const getDetail = (drawNum) => {
        setDrawNum(drawNum);
        axios.get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${drawNum}`)      
        .then(response => {
            console.log(response.data.purchaseLogsList)
            if(response.data.statusCode === 200) {
                setDetailData(response.data)
                setPage(page-1)
            }            
        })
        .catch(error =>{
            // handle error
            console.log(error);
        })
    }
    const handleDelete = (main) => {
        // 建立要傳送的物件
        const Data = {
          drawNum: main.drawNum,
          company: main.company,
          price: main.price,
          engineering: main.engineering,
        };
    
        // 發送 POST 請求到後端 API
        axios
          .post(`${apiUrl}/PurchasePrice/DeleteMain`, Data)
          .then((response) => {
            // 處理刪除成功的回應console.log(response.data)
              if (response.data === 200) {
                  axios
                      .get(`${apiUrl}/PurchasePrice/MainList`)
                      .then(response => {
                          // Update the data
                          setNewData(response.data);
                          alert('刪除成功');
                          setPage(1)
                      })
                      .catch(error => {
                          // handle error
                          console.log(error);
                      });
              } else {
                  alert('刪除失敗，找不到可刪除物件');
              }
          })
          .catch((error) => {
            // 處理刪除失敗的回應
            alert('刪除失敗，找不到可刪除物件')
            console.error(error);
          });
      };  
// 5527003-006
    const handleEdit = (purchasePrice) => {
        setModel('主表修改');
        setEditItem(purchasePrice);
    }  
    return(
        <>
            <button type="button" style={btnMargin} className="btn btn-warning" onClick={() => handlePrint()}>Excel調價紀錄</button>
            <div style={{overflowX: "auto", height: "70vh"}}>
                <table className="table" style={{width: "100%"}}>
                    <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>廠商</th>
                        <th scope="col" style={thStyle.centered}>進價</th>
                        <th scope="col" style={thStyle.centered}>工程內容</th>
                        <th scope="col" style={thStyle.centered}>操作</th>
                    </tr>
                </thead>
                <tbody>
                    {data.purchasePricesList.map((purchasePrice, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{index + 1}</th>
                            <td style={thStyle.centered}>{purchasePrice.drawNum}</td>
                            <td style={thStyle.centered}>{purchasePrice.itemName}</td>
                            <td style={thStyle.centered}>{purchasePrice.company}</td>
                            <td style={thStyle.centered}>{purchasePrice.price}</td>
                            <td style={thStyle.centered}>{purchasePrice.engineering}</td>        
                            <td style={thStyle.centered}>
                                <button type="button" className="btn btn-danger" onClick={() => handleDelete(purchasePrice)}>刪除</button>
                                <button type="button" style={btnMargin} className="btn btn-warning" onClick={() => handleEdit(purchasePrice)}>編輯</button>
                                <button type="button" style={btnMargin} className="btn btn-success" onClick={() => getDetail(purchasePrice.drawNum)}>進價紀錄</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
                <div colSpan="6" style={{textAlign: 'center'}}>
                    <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                    <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
                    <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
                </div>
        </>
    )
}

function DetailListTable({ data,setDetailData,setEditDetail,setModel }) {
    const btnMargin = {
      marginLeft: '2%',
    };
    const thStyle = {
      centered: {
        textAlign: 'center',
      },
    };
    const handleEdit = (detail) => {
        setEditDetail(detail);
        setModel('副表修改');
    };
    const handleDelete = (detail) => {
      // 建立要傳送的物件
      const Data = {
        drawNum: detail.drawNum,
        company: detail.company,
        date: detail.date,
        pk: detail.pk,
        price: detail.price,
        pricePerson: detail.pricePerson,
      };
  
      // 發送 POST 請求到後端 API
      axios
        .post(`${apiUrl}/PurchasePrice/DeleteDetail`, Data)
        .then((response) => {
          // 處理刪除成功的回應console.log(response.data)
            if (response.data === 200) {
                axios
                    .get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${Data.drawNum}`)
                    .then(response => {
                        // Update the data
                        setDetailData(response.data);
                        alert('刪除成功');
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            } else {
                alert('刪除失敗，找不到可刪除物件');
            }
        })
        .catch((error) => {
          // 處理刪除失敗的回應
          alert('刪除失敗，找不到可刪除物件')
          console.error(error);
        });
    };
  
    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={thStyle.centered}>
              #
            </th>
            <th scope="col" style={thStyle.centered}>
              圖號
            </th>
            <th scope="col" style={thStyle.centered}>
              廠商
            </th>
            <th scope="col" style={thStyle.centered}>
              日期
            </th>
            <th scope="col" style={thStyle.centered}>
              進價
            </th>
            <th scope="col" style={thStyle.centered}>
              調價人員
            </th>
            <th scope="col" style={thStyle.centered}>
              操作
            </th>
          </tr>
        </thead>
        <tbody>
          {data.purchaseLogsList.map((detail, index) => (
            <tr key={uuidv4()}>
              <th scope="row" style={thStyle.centered}>
                {index + 1}
              </th>
              <td style={thStyle.centered}>{detail.drawNum}</td>
              <td style={thStyle.centered}>{detail.company}</td>
              <td style={thStyle.centered}>{detail.date}</td>
              <td style={thStyle.centered}>{detail.price}</td>
              <td style={thStyle.centered}>{detail.pricePerson}</td>
              <td style={thStyle.centered}>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleDelete(detail)}
                >
                  刪除
                </button>
                {/* <button
                  type="button"
                  style={btnMargin}
                  className="btn btn-warning"
                  onClick={() => handleEdit(detail)}
                >
                  編輯
                </button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
// onClick={()=>setModel('主表新增')}
function ChooseModel({ setModel,setNewData }) {
    const [selected, setSelected] = useState('');

    const handleChange = (event) => {
        setSelected(event.target.id);
    };
    const selectModel = (event) => {
        if(event.target.id === "btnradio1") {
            setModel('主表新增');
            axios
                .get(`${apiUrl}/PurchasePrice/MainList`)
                .then(response => {
                    // Update the data
                    setNewData(response.data);
                })
                .catch(error => {
                    // handle error
                    console.log(error);
                });
        }else if (event.target.id === "btnradio2") {
            setModel('主表搜尋')
        }else if (event.target.id === "btnradio3") {
            setModel('副表新增')
        }else if (event.target.id === "btnradio4") {
            setModel('讀取模式')
        }
    }
        
    return (
        <div className="btn-group-vertical">
            {/* <label className={selected === 'btnradio1' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio1">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    checked={selected === 'btnradio1'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
               <strong>主表新增</strong>
            </label> */}
            <label className={selected === 'btnradio2' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio2">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    checked={selected === 'btnradio2'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>主表搜尋</strong>
            </label>
            {/* <label className={selected === 'btnradio3' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio3">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio3"
                    checked={selected === 'btnradio3'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>副表新增</strong>
            </label> */}
        </div>
    );
}

function InsertMainForm({setNewData}) {
    const [errorMessage1, setErrorMessage1] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage3, setErrorMessage3] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');
    const handleSubmit = event => {
        let drawNum;
        let company;
        let price;
        let engineering;
        let choose;
        const id = 0;
        
        if(event.target.elements.drawNum.value.trim() !== null) {
            drawNum = event.target.elements.drawNum.value;
        }else {
            drawNum = null;
        }

        if(event.target.elements.company.value.trim() !== null) {
            company = event.target.elements.company.value;
        }else {
            company = null;
        }

        if(event.target.elements.price.value.trim() !== null) {
            price = event.target.elements.price.value;
        }else {
            price = null;
        }

        if(event.target.elements.engineering.value.trim() !== null) {
            engineering = event.target.elements.engineering.value;
        }else {
            engineering = null;
        }
        if( drawNum !== null && company !== null && price !== null && engineering !== null && !Number.isNaN(price)) {
            const data = {
                drawNum,
                company,
                price,
                engineering,
                choose,
                id
            };
            axios
                .post(`${apiUrl}/PurchasePrice/createMain`, data)
                .then(response => {
                    // handle success
                    if (response.data === 200) {
                        axios
                            .get(`${apiUrl}/PurchasePrice/MainList`)
                            .then(response => {
                                // Update the data
                                setNewData(response.data);
                                alert('新增成功');
                            })
                            .catch(error => {
                                // handle error
                                console.log(error);
                            });
                    } else {
                        alert('新增失敗，已有相同的廠商報價');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('新增失敗，已有相同的廠商報價');
                });
        }

    }

    const checkValue1 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage1('欄位不得為空白')
        }else{
            setErrorMessage1('')
        }
    };
    const checkValue2 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage2('欄位不得為空白')
        }else{
            setErrorMessage2('')
        }
    };
    const checkValue3 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage3('欄位不得為空白')
        }else{
            setErrorMessage3('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };
    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="drawNum" className="form-label">圖號
                                <input type="text" className="form-control" id="drawNum" onBlur={checkValue1} />
                                <div id="emailHelp" className="form-text">{errorMessage1}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="company" className="form-label">廠商
                                <input type="text" className="form-control" id="company" onBlur={checkValue2} />
                                <div id="emailHelp" className="form-text">{errorMessage2}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="price" className="form-label">進價
                                <input type="text" className="form-control" id="price" onBlur={checkNumValue} />
                                <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="engineering" className="form-label">工程內容
                                <input type="text" className="form-control" id="engineering" onBlur={checkValue3} />
                                <div id="emailHelp" className="form-text">{errorMessage3}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'/>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="choose" className="form-label">選
                                <input type="text" className="form-control" id="choose" />
                                <div id="emailHelp" className="form-text"/>
                            </label>
                        </div>
                    </div>  
                    <div className='col-3'/>
                </div>
                <div className='row'>
                    <div className='col-5'/>
                    <div className='col-2'>
                        <button type="submit" className="btn btn-dark">提交</button>
                    </div>
                    <div className='col-5'/>
                </div>
            </div>
        
      </form>
    )
}
function EditMainForm({setNewData,editItem}) {

    const [drawNum, setDrawNum] = useState(editItem.drawNum);
    const [company, setCompany] = useState(editItem.company);
    const [price, setPrice] = useState(editItem.price);
    const [engineering, setEngineering] = useState(editItem.engineering);
    const [choose, setChoose] = useState(editItem.choose || '');
    useEffect(() => {
        setDrawNum(editItem.drawNum);
        setCompany(editItem.company);
        setPrice(editItem.price);
        setEngineering(editItem.engineering);
        setChoose(editItem.choose || '');
    }, [editItem]);

    const [errorMessage1, setErrorMessage1] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage3, setErrorMessage3] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');
    const handleSubmit = event => {
        let drawNum;
        let company;
        let price;
        let engineering;
        let choose;
        const id = editItem.id;
        if(event.target.elements.drawNum.value.trim() !== null) {
            drawNum = event.target.elements.drawNum.value;
        }else {
            drawNum = null;
        }

        if(event.target.elements.company.value.trim() !== null) {
            company = event.target.elements.company.value;
        }else {
            company = null;
        }

        if(event.target.elements.price.value.trim() !== null) {
            price = event.target.elements.price.value;
        }else {
            price = null;
        }

        if(event.target.elements.engineering.value.trim() !== null) {
            engineering = event.target.elements.engineering.value;
        }else {
            engineering = null;
        }
        if( drawNum !== null && company !== null && price !== null && engineering !== null && !Number.isNaN(price)) {
            const data = {
                drawNum,
                company,
                price,
                engineering,
                choose,   
                id,
                user:sessionStorage.getItem('userName')            
            };
            axios
                .post(`${apiUrl}/PurchasePrice/editMain`, data)
                .then(response => {
                    // handle success
                    if (response.data === 200) {
                        axios
                            .get(`${apiUrl}/PurchasePrice/MainList`)
                            .then(response => {
                                // Update the data
                                setNewData(response.data);
                                alert('修改成功');
                            })
                            .catch(error => {
                                // handle error
                                console.log(error);
                            });
                    } else {
                        alert('修改失敗，請確認欄位');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('修改失敗，請確認欄位');
                });
        }

    }

    const checkValue1 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage1('欄位不得為空白')
        }else{
            setErrorMessage1('')
        }
    };
    const checkValue2 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage2('欄位不得為空白')
        }else{
            setErrorMessage2('')
        }
    };
    const checkValue3 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage3('欄位不得為空白')
        }else{
            setErrorMessage3('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };
    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="drawNum" className="form-label">圖號
                                <input type="text" className="form-control" id="drawNum" value={drawNum} 
                                onChange={e => setDrawNum(e.target.value)} onBlur={checkValue1} />
                                <div id="emailHelp" className="form-text">{errorMessage1}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="company" className="form-label">廠商
                                <input type="text" className="form-control" id="company" value={company} 
                                onChange={e => setCompany(e.target.value)} onBlur={checkValue2} />
                                <div id="emailHelp" className="form-text">{errorMessage2}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="price" className="form-label">進價
                                <input type="text" className="form-control" id="price" value={price}
                                onChange={e => setPrice(e.target.value)} onBlur={checkNumValue} />
                                <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="engineering" className="form-label">工程內容
                                <input type="text" className="form-control" id="engineering" value={engineering}
                                onChange={e => setEngineering(e.target.value)} onBlur={checkValue3} />
                                <div id="emailHelp" className="form-text">{errorMessage3}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'/>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="choose" className="form-label">選
                                <input type="text" className="form-control" id="choose"
                                onChange={e => setChoose(e.target.value)} value={choose}/>
                                <div id="emailHelp" className="form-text"/>
                            </label>
                        </div>
                    </div>  
                    <div className='col-3'/>
                </div>
                <div className='row'>
                    <div className='col-5'/>
                    <div className='col-2'>
                        <button type="submit" className="btn btn-dark">提交</button>
                    </div>
                    <div className='col-5'/>
                </div>
            </div>
        
      </form>
    )
}
function InsertDetailForm({setDetailData}) {
    const [errorMessage1, setErrorMessage1] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage3, setErrorMessage3] = useState('');
    const [errorMessage4, setErrorMessage4] = useState('');
    const [errorMessage5, setErrorMessage5] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');
    const handleSubmit = event => {
        let drawNum;
        let company;
        let price;
        let date;
        let pricePerson;
        let Pk;
        alert(event.target.elements.Pk.value);
        if(event.target.elements.drawNum.value.trim() !== null) {
            drawNum = event.target.elements.drawNum.value;
        }else {
            drawNum = null;
        }

        if(event.target.elements.company.value.trim() !== null) {
            company = event.target.elements.company.value;
        }else {
            company = null;
        }

        if(event.target.elements.price.value.trim() !== null) {
            price = event.target.elements.price.value;
        }else {
            price = null;
        }

        if(event.target.elements.date.value.trim() !== null) {
            date = event.target.elements.date.value;
        }else {
            date = null;
        }

        if(event.target.elements.pricePerson.value.trim() !== null) {
            pricePerson = event.target.elements.pricePerson.value;
        }else {
            pricePerson = null;
        }

        if(event.target.elements.Pk.value.trim() !== null) {
            Pk = event.target.elements.Pk.value;
        }else {
            Pk = null;
        }


        if( drawNum !== null && company !== null && price !== null && date !== null && !Number.isNaN(price) && pricePerson!== null && Pk !== null) {
            const data = {
                drawNum,
                company,
                price,
                pricePerson,
                Pk,
                date
            };
            axios
                .post(`${apiUrl}/PurchasePrice/createDetail`, data)
                .then(response => {
                    // handle success
                    console.log(response.data)
                    if (response.data === 200) {
                        axios
                            .get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${drawNum}`)
                            .then(response => {
                                // Update the data
                                setDetailData(response.data);
                                alert('新增成功');
                            })
                            .catch(error => {
                                // handle error
                                console.log(error);
                            });
                    } else {
                        alert('新增失敗，已有相同的廠商報價');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('新增失敗，已有相同的廠商報價');
                });
        }

    }

    const checkValue1 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage1('欄位不得為空白')
        }else{
            setErrorMessage1('')
        }
    };
    const checkValue2 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage2('欄位不得為空白')
        }else{
            setErrorMessage2('')
        }
    };
    const checkValue3 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage3('欄位不得為空白')
        }else{
            setErrorMessage3('')
        }
    };
    const checkValue4 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage4('欄位不得為空白')
        }else{
            setErrorMessage4('')
        }
    };
    const checkValue5 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage5('欄位不得為空白')
        }else{
            setErrorMessage5('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };
    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="drawNum" className="form-label">圖號
                                <input type="text" className="form-control" id="drawNum" onBlur={checkValue1} />
                                <div id="emailHelp" className="form-text">{errorMessage1}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="date" className="form-label">日期
                                <input type="text" className="form-control" id="date" onBlur={checkValue2} />
                                <div id="emailHelp" className="form-text">{errorMessage2}</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="price" className="form-label">進價
                            <input type="text" className="form-control" id="price" onBlur={checkNumValue} />
                            <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                        </label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="company" className="form-label">廠商
                            <input type="text" className="form-control" id="company" onBlur={checkValue3} />
                            <div id="emailHelp" className="form-text">{errorMessage3}</div>
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="pricePerson" className="form-label">調價人員
                            <input type="text" className="form-control" id="pricePerson" onBlur={checkValue4}/>
                            <div id="emailHelp" className="form-text">{errorMessage4}</div>
                        </label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="Pk" className="form-label">Pk
                            <input type="text" className="form-control" id="Pk" onBlur={checkValue5}/>
                            <div id="emailHelp" className="form-text">{errorMessage5}</div>
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-5'/>
                <div className='col-2'>
                    <button type="submit" className="btn btn-dark">提交</button>
                </div>
                <div className='col-5'/>
            </div>
        
      </form>
    )
}

function SelectMainForm({setNewData,setDetailData,page,setPage,setDrawNum,drawNum,itemName,setItemName}) {
    const [errorMessage1, setErrorMessage1] = useState('');
    const [company, setCompany] = useState(''); // 新增廠商狀態

    const handlePrint = () => {
        axios.get(`${apiUrl}/PurchasePrice/CreateExcel2?drawNum=${drawNum}&itemName=${itemName}&company=${company}`, {
            responseType: 'blob' // Tell axios to expect a Blob
        })
        .then(response => {
            if (response.data) {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
                const day = String(today.getDate()).padStart(2, '0');
                const filename = `進價一覽表${year}${month}${day}.xlsx`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', filename); // or any other filename you want
                document.body.appendChild(link);
                link.click();
                alert('列印成功');
            } else {
                alert('列印失敗');
            }
        })
        .catch(error => {
            console.log(error);
            alert('發生錯誤 圖號不得為空');
        });
    };
    const handleSubmit = event => {
        event.preventDefault();
        if( drawNum !== null) {
            axios
                .get(`${apiUrl}/PurchasePrice/GetMain?drawNum=${drawNum}&itemName=${itemName}&company=${company}`)
                
                .then(response => {
                    console.log(response.data)
                    // handle success
                    if(response.data.statusCode === 200)
                    {
                        setNewData(response.data);
                        setDetailData(null);
                        setPage(1);
                        alert('搜尋成功');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('搜尋失敗，找不到物件');
                });
        }
    }

    const checkValue1 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage1('欄位不得為空白')
        }else{
            setErrorMessage1('')
        }
    };

    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-4'>
                        <label htmlFor="drawNum" className="form-label">圖號
                            <input type="text" className="form-control" id="drawNum" onBlur={checkValue1} onChange={(e)=>setDrawNum(e.target.value)}/>
                            <div id="emailHelp" className="form-text">{errorMessage1}</div>
                         </label>
                    </div>
                    <div className='col-4'>
                        <label htmlFor="drawNum" className="form-label">物品名
                            <input type="text" className="form-control" id="itemName" onChange={(e)=>setItemName(e.target.value)}/>
                         </label>
                    </div>
                    <div className='col-4'>
                        <label htmlFor="company" className="form-label">廠商
                            <input 
                            type="text" 
                            className="form-control" 
                            id="company" 
                            onChange={(e) => setCompany(e.target.value)} 
                            />
                        </label>
                    </div>
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                    <div className='col-12'>
                        <button type="submit" className="btn btn-dark">提交</button>
                        <button type="button" onClick={()=>handlePrint()} className="btn btn-dark">廠商報價excel</button>
                    </div>
                </div>
            </div>      
      </form>
    )
}


function EditDetailForm({setDetailData,editDetail}) {

    const [drawNum, setDrawNum] = useState(editDetail.drawNum);
    const [date, setDate] = useState(editDetail.date);
    const [price, setPrice] = useState(editDetail.price);
    const [company, setCompany] = useState(editDetail.company);
    const [pricePerson, setPricePerson] = useState(editDetail.pricePerson || '');
    const [pk, setPk] = useState(editDetail.pk || '');
    useEffect(() => {
        setDrawNum(editDetail.drawNum);
        setCompany(editDetail.company);
        setPrice(editDetail.price);
        setDate(editDetail.date);
        setPricePerson(editDetail.pricePerson || '');
        setPk(editDetail.pk || '');
    }, [editDetail]);

    console.log(editDetail)
    const [errorMessage1, setErrorMessage1] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage3, setErrorMessage3] = useState('');
    const [errorMessage4, setErrorMessage4] = useState('');
    const [errorMessage5, setErrorMessage5] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');
    const handleSubmit = event => {
        let drawNum;
        let company;
        let price;
        let date;
        let pricePerson;
        let Pk;
        const id = editDetail.id;
        alert(event.target.elements.Pk.value);
        if(event.target.elements.drawNum.value.trim() !== null) {
            drawNum = event.target.elements.drawNum.value;
        }else {
            drawNum = null;
        }

        if(event.target.elements.company.value.trim() !== null) {
            company = event.target.elements.company.value;
        }else {
            company = null;
        }

        if(event.target.elements.price.value.trim() !== null) {
            price = event.target.elements.price.value;
        }else {
            price = null;
        }

        if(event.target.elements.date.value.trim() !== null) {
            date = event.target.elements.date.value;
        }else {
            date = null;
        }

        if(event.target.elements.pricePerson.value.trim() !== null) {
            pricePerson = event.target.elements.pricePerson.value;
        }else {
            pricePerson = null;
        }

        if(event.target.elements.Pk.value.trim() !== null) {
            Pk = event.target.elements.Pk.value;
        }else {
            Pk = null;
        }


        if( drawNum !== null && company !== null && price !== null && date !== null && !Number.isNaN(price) && pricePerson!== null && Pk !== null) {
            const data = {
                drawNum,
                company,
                price,
                pricePerson,
                Pk,
                date,
                id
            };
            axios
                .post(`${apiUrl}/PurchasePrice/editDetail`, data)
                .then(response => {
                    // handle success
                    
                    if (response.data === 200) {
                        axios
                            .get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${drawNum}`)
                            .then(response => {
                                // Update the data
                                setDetailData(response.data);
                                console.log(response.data)
                                alert('修改成功');
                            })
                            .catch(error => {
                                // handle error
                                console.log(error);
                            });
                    } else {
                        alert('修改失敗，請確認欄位值');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('修改失敗，請確認欄位值');
                });
        }

    }

    const checkValue1 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage1('欄位不得為空白')
        }else{
            setErrorMessage1('')
        }
    };
    const checkValue2 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage2('欄位不得為空白')
        }else{
            setErrorMessage2('')
        }
    };
    const checkValue3 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage3('欄位不得為空白')
        }else{
            setErrorMessage3('')
        }
    };
    const checkValue4 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage4('欄位不得為空白')
        }else{
            setErrorMessage4('')
        }
    };
    const checkValue5 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage5('欄位不得為空白')
        }else{
            setErrorMessage5('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };
    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="drawNum" className="form-label">圖號
                                <input type="text" className="form-control" id="drawNum" value={drawNum}
                                onChange={e => setDrawNum(e.target.value)} onBlur={checkValue1} />
                                <div id="emailHelp" className="form-text">{errorMessage1}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="date" className="form-label">日期
                                <input type="text" className="form-control" id="date" value={date}
                                onChange={e => setDate(e.target.value)} onBlur={checkValue2} />
                                <div id="emailHelp" className="form-text">{errorMessage2}</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="price" className="form-label">進價
                            <input type="text" className="form-control" id="price" value={price}
                            onChange={e => setPrice(e.target.value)} onBlur={checkNumValue} />
                            <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                        </label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="company" className="form-label">廠商
                            <input type="text" className="form-control" id="company" value={company}
                            onChange={e => setCompany(e.target.value)} onBlur={checkValue3} />
                            <div id="emailHelp" className="form-text">{errorMessage3}</div>
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="pricePerson" className="form-label">調價人員
                            <input type="text" className="form-control" id="pricePerson" value={pricePerson}
                            onChange={e => setPricePerson(e.target.value)} onBlur={checkValue4}/>
                            <div id="emailHelp" className="form-text">{errorMessage4}</div>
                        </label>
                    </div>
                </div>
                <div className='col-6'>
                    <div className="mb-3">
                        <label htmlFor="Pk" className="form-label">Pk
                            <input type="text" className="form-control" id="Pk"  value={pk}
                            onChange={e => setPk(e.target.value)} onBlur={checkValue5}/>
                            <div id="emailHelp" className="form-text">{errorMessage5}</div>
                        </label>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-5'/>
                <div className='col-2'>
                    <button type="submit" className="btn btn-dark">提交</button>
                </div>
                <div className='col-5'/>
            </div>
        
      </form>
    )
}
