import React, { useState } from 'react';
import axios from 'axios';
import { 
    Box, 
    Grid, 
    TextField, 
    Button, 
    Typography, 
    Paper 
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import { v4 as uuidv4 } from 'uuid';
import { apiUrl } from '../../apiUrl/ApiUrl';

const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    { field: 'drawNum', headerName: '圖號', width: 150, editable: true },
    { field: 'quantity', headerName: '數量', width: 150, editable: true },
    { field: 'client', headerName: '客戶', width: 150, editable: true },
];

export default function DefectiveRegistration() {
    const [formData, setFormData] = useState({ drawNum: '', quantity: '', client: '' });
    const [gridData, setGridData] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAdd = () => {
        const { drawNum, quantity, client } = formData;

        if (!drawNum || !quantity || !client) {
            alert('請填寫所有欄位！');
            return;
        }

        const newEntry = {
            id: uuidv4(),
            drawNum,
            quantity: parseInt(quantity, 10),
            client,
        };

        setGridData([...gridData, newEntry]);
        setFormData({ drawNum: '', quantity: '', client: '' });
    };

    const handleSave = async () => {
        if (gridData.length === 0) {
            alert('表格中沒有資料可以儲存！');
            return;
        }

        try {
            const dataToSend = gridData.map(({ id, ...rest }) => rest);

            const response = await axios.post(`${apiUrl}/OrderManage/RegisterBulk`, dataToSend);
            if (response.status === 200) {
                setResponseMessage('儲存成功！');
                setGridData([]);
            } else {
                setResponseMessage('儲存失敗，請稍後再試。');
            }
        } catch (error) {
            setResponseMessage('儲存失敗，請稍後再試。');
            console.error(error);
        }
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                不良品登錄
            </Typography>
            <Paper sx={{ padding: 2, marginBottom: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="圖號"
                            variant="outlined"
                            name="drawNum"
                            value={formData.drawNum}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="數量"
                            variant="outlined"
                            name="quantity"
                            type="number"
                            value={formData.quantity}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="客戶"
                            variant="outlined"
                            name="client"
                            value={formData.client}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} className="d-flex justify-content-end">
                        <Button 
                            variant="contained" 
                            color="primary" 
                            startIcon={<SaveIcon />} 
                            onClick={handleAdd}
                        >
                            建立
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ padding: 2, marginBottom: 3 }}>
                <Typography variant="h6" gutterBottom>
                    建立結果
                </Typography>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        checkboxSelection
                    />
                </div>
            </Paper>

            <Box className="d-flex justify-content-end" sx={{ marginBottom: 3 }}>
                <Button 
                    variant="contained" 
                    color="success" 
                    endIcon={<SaveIcon />} 
                    onClick={handleSave}
                >
                    保存
                </Button>
                <Button 
                    variant="outlined" 
                    color="secondary" 
                    startIcon={<ClearIcon />} 
                    onClick={() => setGridData([])}
                    sx={{ marginLeft: 2 }}
                >
                    重置
                </Button>
            </Box>

            {responseMessage && (
                <Typography variant="body1" color="textPrimary">
                    {responseMessage}
                </Typography>
            )}
        </Box>
    );
}
